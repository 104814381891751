import React from "react";
import DOMPurify from "dompurify";
import Input from "../components/forms/Input";
import useInputValidator from "../hooks/useInputValidator";
import { isEmail } from "validator";
import { validate } from "../components/forms/validators";
import useError from "../hooks/useError";
import useBoolean from "../hooks/useBoolean";
import sendRequest from "../utils/sendRequest";
import "./auth.scss";

function SignUpPage(props) {
  const emailInput = useInputValidator(isEmail);
  const passwordInput = useInputValidator(validate);
  const passwordConfirmInput = useInputValidator(validate);

  //set states
  const serverError = useError();
  const isLoading = useBoolean(false);
  const isSent = useBoolean(false);

  //form validation and safety
  const formIsValid =
    emailInput.isValid && passwordInput.isValid && passwordConfirmInput.isValid;

  const formIsNotSafe =
    emailInput.isNotSafe ||
    passwordInput.isNotSafe ||
    passwordConfirmInput.isNotSafe;

  //submit form
  const formSubmissionHandler = async (event) => {
    event.preventDefault();

    //don't submit form if unsafe or invalid
    if (!formIsValid) {
      if (!emailInput.isValid) emailInput.clickHandler();
      if (!passwordInput.isValid) passwordInput.clickHandler();
      if (!passwordConfirmInput.isValid) passwordConfirmInput.clickHandler();
      return;
    }
    if (formIsNotSafe) return;

    isLoading.setTrue();

    //get data
    const data = {
      email: DOMPurify.sanitize(emailInput.value),
      password: DOMPurify.sanitize(passwordInput.value),
      passwordConfirm: DOMPurify.sanitize(passwordConfirmInput.value),
    };

    console.log(data);

    //send request and handle errors
    const res = await sendRequest(data, "login");
    if (res.success) {
      isLoading.setFalse();
      isSent.setTrue();
    } else if (!res.success) {
      serverError.errorMessageHandler(res.errorMessage);
      isLoading.setFalse();
      serverError.setErrorTrue();
    }
  };
  return (
    <div className="auth-container">
      <form action="" onSubmit={formSubmissionHandler}>
        <div className="d-input">
          <div className="d-input__wrapper">
            <Input
              id="email"
              label="Email"
              placeholder="Your Email"
              valueChangeHandler={emailInput.valueChangeHandler}
              inputBlurHandler={emailInput.inputBlurHandler}
              enteredValue={emailInput.value}
              hasError={emailInput.hasError}
              isNotSafe={emailInput.isNotSafe}
              isTouched={emailInput.isTouched}
              errorText="Please enter a full email"
            />
          </div>
          <div className="d-input__wrapper">
            <Input
              id="password"
              label="password"
              placeholder="Your Password"
              valueChangeHandler={passwordInput.valueChangeHandler}
              inputBlurHandler={passwordInput.inputBlurHandler}
              enteredValue={passwordInput.value}
              hasError={passwordInput.hasError}
              isNotSafe={passwordInput.isNotSafe}
              isTouched={passwordInput.isTouched}
              errorText="Please enter a full email"
            />
          </div>
          <div className="d-input__wrapper">
            <Input
              id="passwordConfirm"
              label="passwordConfirm"
              placeholder="Confirm your Password"
              valueChangeHandler={passwordConfirmInput.valueChangeHandler}
              inputBlurHandler={passwordConfirmInput.inputBlurHandler}
              enteredValue={passwordConfirmInput.value}
              hasError={passwordConfirmInput.hasError}
              isNotSafe={passwordConfirmInput.isNotSafe}
              isTouched={passwordConfirmInput.isTouched}
              errorText="Please enter a full email"
            />
          </div>

          <div className="icons">
            <svg className="ico">
              <use href={"#footer-apple"} />
            </svg>
            <svg className="ico">
              <use href={"#footer-google"} />
            </svg>
            <svg className="ico">
              <use href={"#book"} />
            </svg>
          </div>

          {serverError.hasError && (
            <div className="error-container">
              <p>{serverError.errorMessage || "something went wrong"}</p>
            </div>
          )}

          <div className="d-input__submit">
            {!isLoading.boolean && !isSent.boolean && (
              <button className="bt bt-lg top-margin" type="submit">
                Send message
              </button>
            )}
            {isLoading.boolean && (
              <button
                className="bt bt-lg bt-loading top-margin"
                style={{ disabled: true }}
              >
                Loading...
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default SignUpPage;
