import React from "react";

function TermsOfUseText(props) {
  return (
    <div className="terms-modal__body">
      <h2>Terms and Conditions:</h2>
      <ol>
        <li>
          <p>
            Eligibility: By accessing and using our game, you confirm that you
            are of legal age according to the laws of your jurisdiction. If you
            are accessing the game on behalf of an organization, you warrant
            that you have the necessary authority to do so.
          </p>
        </li>
        <li>
          <p>
            User Conduct: You agree to use the game solely for lawful purposes
            and in compliance with these terms and any applicable laws and
            regulations. You will not engage in any activity that may disrupt or
            interfere with the game's functionality or integrity.
          </p>
        </li>
        <li>
          <p>
            Intellectual Property: All intellectual property rights, including
            trademarks, copyrights, and patents, associated with the game belong
            to the respective owners. You may not reproduce, distribute, or
            modify any content from the game without obtaining prior written
            permission.
          </p>
        </li>
        <li>
          <p>
            Privacy: We respect your privacy and handle your personal
            information in accordance with our Privacy Policy. By using the
            game, you consent to the collection, storage, and use of your
            information as described in the Privacy Policy.
          </p>
        </li>
        <li>
          <p>
            Disclaimer of Warranty: The game is provided on an "as is" basis
            without any warranties or representations, express or implied. We do
            not guarantee the accuracy, reliability, or availability of the
            game, and you use it at your own risk.
          </p>
        </li>
        <li>
          <p>
            Limitation of Liability: In no event shall we be liable for any
            direct, indirect, incidental, consequential, or punitive damages
            arising out of or in connection with the use of the game, even if we
            have been advised of the possibility of such damages.
          </p>
        </li>
        <li>
          <p>
            Modifications: We reserve the right to modify or terminate the game,
            its features, or these terms and conditions at any time without
            prior notice. It is your responsibility to review the updated terms
            regularly.
          </p>
        </li>
        <li>
          <p>
            Governing Law: These terms and conditions shall be governed by and
            construed in accordance with the laws of [Jurisdiction]. Any
            disputes arising out of or in connection with these terms shall be
            subject to the exclusive jurisdiction of the courts of
            [Jurisdiction].
          </p>
        </li>
      </ol>
      <p>
        By using our game, you agree to comply with these terms and conditions.
        If you do not agree with any of these terms, please refrain from using
        the game.
      </p>
      <h2>Terms and Conditions:</h2>
      <ol>
        <li>
          <p>
            Eligibility: By accessing and using our game, you confirm that you
            are of legal age according to the laws of your jurisdiction. If you
            are accessing the game on behalf of an organization, you warrant
            that you have the necessary authority to do so.
          </p>
        </li>
        <li>
          <p>
            User Conduct: You agree to use the game solely for lawful purposes
            and in compliance with these terms and any applicable laws and
            regulations. You will not engage in any activity that may disrupt or
            interfere with the game's functionality or integrity.
          </p>
        </li>
        <li>
          <p>
            Intellectual Property: All intellectual property rights, including
            trademarks, copyrights, and patents, associated with the game belong
            to the respective owners. You may not reproduce, distribute, or
            modify any content from the game without obtaining prior written
            permission.
          </p>
        </li>
        <li>
          <p>
            Privacy: We respect your privacy and handle your personal
            information in accordance with our Privacy Policy. By using the
            game, you consent to the collection, storage, and use of your
            information as described in the Privacy Policy.
          </p>
        </li>
        <li>
          <p>
            Disclaimer of Warranty: The game is provided on an "as is" basis
            without any warranties or representations, express or implied. We do
            not guarantee the accuracy, reliability, or availability of the
            game, and you use it at your own risk.
          </p>
        </li>
        <li>
          <p>
            Limitation of Liability: In no event shall we be liable for any
            direct, indirect, incidental, consequential, or punitive damages
            arising out of or in connection with the use of the game, even if we
            have been advised of the possibility of such damages.
          </p>
        </li>
        <li>
          <p>
            Modifications: We reserve the right to modify or terminate the game,
            its features, or these terms and conditions at any time without
            prior notice. It is your responsibility to review the updated terms
            regularly.
          </p>
        </li>
        <li>
          <p>
            Governing Law: These terms and conditions shall be governed by and
            construed in accordance with the laws of [Jurisdiction]. Any
            disputes arising out of or in connection with these terms shall be
            subject to the exclusive jurisdiction of the courts of
            [Jurisdiction].
          </p>
        </li>
      </ol>
      <p>
        By using our game, you agree to comply with these terms and conditions.
        If you do not agree with any of these terms, please refrain from using
        the game.
      </p>
    </div>
  );
}

export default TermsOfUseText;
