import "./assets/css/style.css";
import "./App.scss";
import { useEffect, Suspense, useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import loadabel from "@loadable/component";
import ScrollToTop from "./components/UI/animations/ScrollToTop";
import Navigation from "./components/navigation/Navigation";
import Footer from "./components/footer/Footer";
import Loading from "./components/UI/Loading";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import { AppleCallback } from "./pages/AppleCallback";
import GoogleAuthCallback from "./handlers/GoogleAuthCallback";
import CustomTokenAuth from "./pages/CustomTokenAuth";
import LanguageContext from "./storage/LanguageContext";

const FrontPage = loadabel(() => import("./pages/FrontPage"), {
  fallback: <Loading />,
});
const WhitePaperPage = loadabel(() => import("./pages/WhitePaperPage"), {
  fallback: <Loading />,
});
const ContactPage = loadabel(() => import("./pages/ContactPage"), {
  fallback: <Loading />,
});
const AboutPage = loadabel(() => import("./pages/AboutPage"), {
  fallback: <Loading />,
});
const WaitlistPage = loadabel(() => import("./pages/WaitlistPage"), {
  fallback: <Loading />,
});
const NotFound = loadabel(() => import("./pages/NotFound"), {
  fallback: <Loading />,
});

function App() {
  //set navigation state
  const { pathname } = useLocation();

  // const env = process.env.REACT_APP_BASE_URL;
  // console.log(env);

  const urls = ["/", "/contact", "/about", "/waitlist"];

  const navIsVisible = urls.includes(pathname);

  const { copyIsLoading } = useContext(LanguageContext);

  //preload pages
  useEffect(() => {
    console.log(process.env.REACT_APP_API_BASE_URL);
    FrontPage.preload();
    WhitePaperPage.preload();
    ContactPage.preload();
    AboutPage.preload();
    WaitlistPage.preload();
  }, []);
  return (
    <div>
      {copyIsLoading ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>
          {navIsVisible && <Navigation />}
          <main className="main">
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<FrontPage />} />
              {/* <Route path="/white-paper" element={<WhitePaperPage />} /> */}
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/waitlist" element={<WaitlistPage />} />
              <Route path="/loading" element={<Loading />} />
              <Route path="/sign-in" element={<SignInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/redirect" element={<AppleCallback />} />
              <Route path="/auth/google" element={<GoogleAuthCallback />} />
              <Route path="/auth/customToken" element={<CustomTokenAuth />} />
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </main>
          {navIsVisible && <Footer />}
        </Suspense>
      )}
    </div>
  );
}

export default App;
