import React from "react";
import { Link } from "react-router-dom";

function SecondaryBt({ children, isLarge, text, link, onClick, extra }) {
  const className = `bt ${isLarge && "bt-lg"} bt-secondary ${extra}`;
  return (
    <Link className={className} to={link} onClick={onClick}>
      {text}
      {children}
    </Link>
  );
}

export default SecondaryBt;
