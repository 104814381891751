// src/components/AutoGoogleSignIn.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebase";
import {
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
} from "firebase/auth";

const GoogleAuthCallback = () => {
  const [status, setStatus] = useState("Redirecting to Google sign-in...");
  const [showFallbackButton, setShowFallbackButton] = useState(false);
  const [uid, setUid] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const signInAttempted = sessionStorage.getItem("signInAttempted");

    getRedirectResult(auth)
      .then((result) => {
        sessionStorage.removeItem("signInAttempted");
        if (result?.user) {
          // User is signed in, get the ID token and redirect
          result.user.getIdToken().then((idToken) => {
            console.log(idToken);
            const backendEndpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/google`;

            setStatus("Redirecting to Game...");

            axios
              .post(backendEndpoint, { idToken })
              .then((response) => {
                const { uid } = response.data.data;
                console.log(response.data);
                setUid(uid);
                redirectToDeepLink(`gg.d4e.dev://auth?uid=${uid}`);
              })
              .catch((error) => {
                console.error("Error sending ID token to backend:", error);
                navigate("/error");
              });

            // window.location.href = `${backendEndpoint}/auth/google/?idToken=${encodeURIComponent(idToken)}`;
          });
        } else if (!signInAttempted) {
          // No user and no prior sign-in attempt, initiate sign-in
          sessionStorage.setItem("signInAttempted", "true");
          const provider = new GoogleAuthProvider();
          provider.setCustomParameters({
            prompt: "select_account", // This prompts the user to select an account
          });
          signInWithRedirect(auth, provider);
        }
      })
      .catch((error) => {
        sessionStorage.removeItem("signInAttempted");
        console.error("Error processing the redirect result:", error);
        navigate("/error"); // Redirect to an error page
      });
  }, [navigate]);

  const redirectToDeepLink = (url) => {
    window.location.href = url;
    const now = Date.now();
    setTimeout(() => {
      if (Date.now() - now < 2000) {
        setShowFallbackButton(true);
      }
    }, 1500);
  };

  return (
    <div>
      {!showFallbackButton && (
        <p
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "10px 20px",
            fontSize: "20px",
          }}
        >
          {status}
        </p>
      )}
      {showFallbackButton && (
        <button
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "10px 20px",
            fontSize: "16px",
          }}
          className="bt bt-lg"
          onClick={() => redirectToDeepLink(`gg.d4e.dev://auth?uid=${uid}`)}
        >
          Open in App
        </button>
      )}
    </div>
  );
};

export default GoogleAuthCallback;
