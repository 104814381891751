import React, { useState } from 'react';
import {auth} from "../utils/firebase";
import {signInWithCustomToken} from "firebase/auth";

const CustomTokenAuth = () => {
    const [customToken, setCustomToken] = useState('');

    const handleTokenChange = (e) => {
        setCustomToken(e.target.value);
    };

    const handleSignIn = () => {
        signInWithCustomToken(auth, customToken)
            .then((userCredential) => {
                // User is signed in
                const user = userCredential.user;
                console.log('User authenticated:', user);
            })
            .catch((error) => {
                // Handle error
                console.error('Error authenticating user:', error);
            });
    };

    return (
        <div>
            <input type="text" value={customToken} onChange={handleTokenChange} />
            <button onClick={handleSignIn}>Authenticate</button>
        </div>
    );
};

export default CustomTokenAuth;
