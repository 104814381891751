import React from "react";
import { motion } from "framer-motion";

//shake animation for errors
const ShakeAnimation = ({ children, isShaking }) => {
  const shakeVariants = {
    initial: {
      x: 0,
    },
    shake: {
      x: [-5, 5, -5, 5, -2, 2, 0],
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      className="shake-element"
      initial="initial"
      animate={isShaking ? "shake" : "initial"}
      variants={shakeVariants}
    >
      {children}
    </motion.div>
  );
};

export default ShakeAnimation;
