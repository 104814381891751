import { useReducer } from "react";
import DOMPurify from "dompurify";

//checks for unsage chatacters
export const containsUnsafeCharacters = (input) => {
  const sanitizedInput = DOMPurify.sanitize(input);
  return sanitizedInput !== input;
};

//initial state
const initialInputState = {
  value: "",
  isTouched: false,
  clicked: false,
};

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT")
    return { value: action.value, isTouched: state.isTouched };
  if (action.type === "BLUR") return { value: state.value, isTouched: true };
  if (action.type === "RESET") return initialInputState;
  if (action.type === "CLICK") return { value: state.value, clicked: true };
};

const useInputValidator = (validateValue) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  //values
  const isValid = validateValue(inputState.value);
  const hasError = (!isValid && inputState.isTouched) || inputState.clicked;
  const isNotSafe = containsUnsafeCharacters(inputState.value);
  const isTouched = inputState.isTouched;

  //handler functions
  const valueChangeHandler = (event) => {
    dispatch({ type: "INPUT", value: event.target.value });
  };

  const inputBlurHandler = (event) => {
    dispatch({ type: "BLUR" });
  };

  const clickHandler = () => {
    dispatch({ type: "CLICK" });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  return {
    value: inputState.value,
    isValid,
    isNotSafe,
    isTouched,
    hasError,
    valueChangeHandler,
    inputBlurHandler,
    clickHandler,
    reset,
  };
};

export default useInputValidator;
