import React from "react";

function Logo({ path, label, xlink }) {
  return (
    <svg className="ico">
      <use href={xlink} />
    </svg>
  );
}

export default Logo;
