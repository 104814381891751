import { firestore } from "./firebase";
import { collection, getDocs, limit, query } from "firebase/firestore";

function formatFirebaseTimestamp(createdAt) {
  const date = new Date(createdAt.seconds * 1000);

  // Format the date to a readable string
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export const fetchData = async (collectionName, pageSize = 4) => {
  const collectionRef = collection(firestore, collectionName);
  let firestoreQuery = query(collectionRef);

  //   let orderByFields = "name";
  //   let orderTypes = "asc";

  //   firestoreQuery = query(firestoreQuery, orderBy(orderByFields, orderTypes));

  try {
    firestoreQuery = query(firestoreQuery, limit(pageSize));

    const querySnapshot = await getDocs(firestoreQuery);
    const documents = querySnapshot.docs;
    const data = documents.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      //check if .createdAt exists and assign "N/A" if it doesn't
      createdAt: doc.data()?.createdAt
        ? formatFirebaseTimestamp(doc.data().createdAt)
        : "N/A",
    }));
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const fetchNewsData = async (pageSize = 4) => {
  try {
    const newsData = await fetchData("news", pageSize);

    return newsData;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const fetchTranslations = async () => {
  const collectionName = "translations";

  const res = await fetchData(collectionName, 100);

  // console.log(data);
  return res;
};

export const fetchTranslationsWithRetry = async (maxRetries = 3) => {
  const collectionName = "translations";
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const res = await fetchData(collectionName, 100);
      return res; // Return the result if successful
    } catch (error) {
      console.error(
        `Error fetching translations (attempt ${retries + 1}):`,
        error
      );
      retries++; // Increment retries counter

      // Delay before retrying (e.g., exponential backoff)
      await new Promise((resolve) => setTimeout(resolve, 1000 * retries)); // Adjust delay as needed
    }
  }

  // If maxRetries is exceeded, throw an error or return null
  console.error(`Failed to fetch translations after ${maxRetries} attempts`);
  return null; // or throw new Error('Failed to fetch translations');
};
