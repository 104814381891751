export const navList = [
  {
    label: "Home",
    link: "/",
    xlink: "#home",
  },
  {
    label: "About",
    link: "/about",
    xlink: "#controller",
  },
  // {
  //   label: "Whitepaper",
  //   link: "/white-paper",
  //   xlink: "#document",
  // },
  {
    label: "Contact",
    link: "/contact",
    xlink: "#contact",
  },
];
