import React, { useContext } from "react";
import { navList } from "./navInfo";
import { NavLink } from "react-router-dom";
import LanguageContext from "../../storage/LanguageContext";

function NavItem({ label, link, onClick, xlink }) {
  return (
    <li>
      <NavLink to={link} onClick={onClick}>
        <svg className="ico">
          <use href={xlink} />
        </svg>
        {label}
      </NavLink>
    </li>
  );
}

function NavItems({ onClick }) {
  const { copy } = useContext(LanguageContext);
  const modifyNav = (nav) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(nav, "text/html");
    const items = doc.querySelectorAll("li");
    const texts = Array.from(items).map((item) => item.textContent.trim());
    navList.forEach((item, index) => {
      item.label = texts[index];
    });
    return navList;
  };

  const navItemList = modifyNav(copy.nav).map((item) => (
    <NavItem
      label={item.label}
      link={item.link}
      xlink={item.xlink}
      key={item.label}
      onClick={onClick}
    />
  ));
  return <ul>{navItemList}</ul>;
}

export default NavItems;
