import React from "react";
import ShakeAnimation from "../UI/animations/ShakeAnimation";

function Input({
  label,
  id,
  type,
  placeholder,
  valueChangeHandler,
  inputBlurHandler,
  enteredValue,
  hasError,
  errorText,
  isNotSafe,
  isTouched,
}) {
  const className = `d-input__container ${
    hasError || isNotSafe ? "error" : isTouched ? "success" : ""
  }`;
  const inputType = type || "text";

  const inputField =
    id === "message__text" ? (
      <textarea
        className="d-input__field"
        id={id}
        type="text"
        placeholder={placeholder}
        onChange={valueChangeHandler}
        onBlur={inputBlurHandler}
        value={enteredValue}
      />
    ) : (
      <input
        className="d-input__field"
        id={id}
        type={inputType}
        placeholder={placeholder}
        onChange={valueChangeHandler}
        onBlur={inputBlurHandler}
        value={enteredValue}
        autoComplete="on"
      />
    );

  return (
    <div className={className}>
      <label className="d-input__label" htmlFor={id}>
        {label}
      </label>
      <ShakeAnimation isShaking={hasError || isNotSafe}>
        {inputField}
      </ShakeAnimation>
      {hasError && <p className="d-input__message">{errorText}</p>}
      {isNotSafe && (
        <p className="d-input__message">Unsafe Characters Detected</p>
      )}
      {/* {!hasError && !isNotSafe && <p class="d-input__message">success</p>} */}
    </div>
  );
}

export default Input;
