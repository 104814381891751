import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  // Add any additional headers as needed
};

const config = {
  headers: headers,
  // Add other configuration options if necessary
};

const sendRequest = async (data, keyword) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/${keyword}`,
      data,
      config
    );

    return { success: true, responseData: response.data };
  } catch (error) {
    if (error.response.data.message) {
      return { success: false, errorMessage: error.response.data.message };
    }
    return {
      success: false,
      errorMessage: "networt error, please try again later",
    };
  }
};

export default sendRequest;
