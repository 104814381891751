import React from "react";
import { motion, AnimatePresence } from "framer-motion";

function SlowAppearAnimation({ boolean, children }) {
  return (
    <AnimatePresence initial={false}>
      {boolean && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.9 },
          }}
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // exit={{ opacity: 0 }}
          // transition={{ duration: 0.5, ease: "easeOut" }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default SlowAppearAnimation;
