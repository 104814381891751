import { useState } from "react";

function useBoolean(initialState) {
  const [boolean, setBoolean] = useState(initialState);

  const setTrue = () => {
    setBoolean(true);
  };
  const setFalse = () => {
    setBoolean(false);
  };

  const setReverse = () => {
    setBoolean((prevSate) => !prevSate);
  };
  return {
    boolean,
    setTrue,
    setFalse,
    setReverse,
  };
}

export default useBoolean;
