import { useState } from "react";

const useError = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const errorMessageHandler = (errorDataMessage) => {
    setErrorMessage(errorDataMessage);
  };

  const setErrorTrue = () => {
    setHasError(true);
  };

  const reset = () => {
    setErrorMessage("");
    setHasError(false);
  };

  return {
    hasError,
    reset,
    setErrorTrue,
    errorMessageHandler,
    errorMessage,
  };
};

export default useError;
