import parse from "html-react-parser";
import DOMPurify from "dompurify";

export const sanitizeAndParse = (content) => {
  return parse(DOMPurify.sanitize(content));
};

export function extractTextFromHtml(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return DOMPurify.sanitize(doc.body.textContent.trim());
}

export function splitStringAfterFirstBreak(htmlString) {
  const breakMarker = "<p><br></p>";
  const breakIndex = htmlString.indexOf(breakMarker);

  if (breakIndex === -1) {
    return [htmlString]; // Return the original string in an array if the marker is not found
  }

  // Split into two parts: before and after the marker
  const firstPart = htmlString.substring(0, breakIndex);
  const secondPart = htmlString.substring(breakIndex + breakMarker.length);

  return { firstPart, secondPart };
}

export function createArrayFromList(listString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(listString, "text/html");
  const items = doc.querySelectorAll("li");
  const texts = Array.from(items).map((item) => item.textContent.trim());
  return texts;
}

export function scrollTo(elementID) {
  const navbarHeight = 86;

  const targetElement = document.getElementById(elementID || "base");

  if (targetElement) {
    const elementPosition = targetElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - navbarHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  return null;
}
