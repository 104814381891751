import React, { useEffect } from "react";
import TermsOfUseText from "./TermsOfUseText";
import SlowAppearAnimation from "../animations/SlowAppearAnimation";
import PageTransition from "../animations/PageTransitions";
import { AnimatePresence, motion } from "framer-motion";

function TermsModal({ onClose, isVisible }) {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => (document.body.style.overflowY = "auto");
  });
  const backdrop = {
    visible: { opacity: 1, transition: { duration: 0.3 } },
    hidden: { opacity: 0 },
    exit: { opacity: 0 },
  };
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="modal"
          mode="wait"
          className="backdrop"
          variants={backdrop}
          //     animate="visible"
          //     initial="hidden"
          //     exit="exit"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <div className="terms-modal">
            <div className="terms-modal__cls" onClick={onClose}>
              Close
            </div>
            <TermsOfUseText />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default TermsModal;
