import React from "react";
import "./dropdown.scss";

const Dropdown = (props) => {
  const { icon, options, onChange, value } = props;

  return (
    <div className="select-wrapper">
      {/* <div className="icon-wrapper">{icon}</div> */}
      <select
        id="lang"
        onChange={(event) => onChange(event.target.value)}
        className="bt bt-secondary"
        value={value}
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
