import React, { createContext, useState, useEffect } from "react";
import {
  fetchTranslations,
  fetchTranslationsWithRetry,
} from "../utils/data.helpers";

const LanguageContext = createContext({
  language: "en",
  setLanguage: () => {},
  copy: {},
  copyIsLoading: true,
});

// LanguageProvider component

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );
  const [translations, setTranslations] = useState([]);
  const [copyIsLoading, setCopyIsLoading] = useState(true);
  const [copy, setCopy] = useState({});

  const switchLanguage = (lang) => {
    setLanguage(lang);
    setCopy(translations.find((item) => item.id === lang) || {});
    localStorage.setItem("language", lang);
    document.body.classList.remove();
    document.body.classList.add("ka");
  };

  useEffect(() => {
    const setTexts = async () => {
      setCopyIsLoading(true);
      const res = await fetchTranslationsWithRetry();
      setCopyIsLoading(false);
      setTranslations(res);
      setCopy(
        res.find((item) =>
          localStorage.getItem("language")
            ? item.id === localStorage.getItem("language")
            : item.id === "en"
        ) || {}
      );
      if (localStorage.getItem("language") === "ka") {
        document.body.classList.add("ka");
      } else {
        document.body.classList.remove("ka");
      }
    };
    setTexts();
  }, [setCopy, setTranslations]);

  return (
    <LanguageContext.Provider
      value={{ language, switchLanguage, copyIsLoading, copy }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
