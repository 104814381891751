import React, { useContext } from "react";
import { socialsListInfo } from "./listsInfo";
import LanguageContext from "../../storage/LanguageContext";
import twitter from "../../svgs/logos/twitter.svg";
import discord from "../../svgs/logos/discord.svg";

import Logos from "../UI/logos/Logos";

function SocialsItem({ svg, link }) {
  return (
    <a href="#!">
      <img src={svg} alt="" />
    </a>
  );
}

function Socials({ addClass, extended }) {
  const { copy } = useContext(LanguageContext);
  const { socials } = copy;

  const socialsListInfo = [
    {
      svg: twitter,
      link: JSON.parse(socials).filter((item) => item.label === "twitter")[0]
        .link,
    },
    {
      svg: discord,
      link: JSON.parse(socials).filter((item) => item.label === "discord")[0]
        .link,
    },
  ];

  let socialsList;
  if (extended) {
    socialsList = <Logos socials={socials} />;
  } else {
    socialsList = socialsListInfo.map((item) => (
      <SocialsItem svg={item.svg} link={item.link} key={item.svg} />
    ));
  }
  return <div className={addClass}>{socialsList}</div>;
}

export default Socials;
