import React from "react";
import { images } from "./UIInfos";
import red from "../../assets/img/gem-red.png";
import blue from "../../assets/img/gem-blue.png";
import green from "../../assets/img/gem-green.png";
import purple from "../../assets/img/gem-purple.png";
import yellow from "../../assets/img/gem-yellow.png";

function ImgItem(img) {
  return (
    <div>
      <img src={img} alt="" />
    </div>
  );
}

function Loading(props) {
  const imageComponent = images.map((item) => (
    <ImgItem img={item.img} key={item.name} />
  ));
  return (
    <div className="loading">
      <div className="load">
        <div>
          <img src={red} alt="" />
        </div>
        <div>
          <img src={blue} alt="" />
        </div>
        <div>
          <img src={green} alt="" />
        </div>
        <div>
          <img src={purple} alt="" />
        </div>
        <div>
          <img src={yellow} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Loading;
