// PhoneAuth.js
import React, { useState } from 'react';
import { auth } from '../utils/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const PhoneAuth = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [verificationId, setVerificationId] = useState(null);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleSendCode = async () => {
    try {
      const appVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
        'size': 'invisible',
        'callback': (response) => {
          // This callback is optional and not required for the code to work.
          // If you don't have any specific functionality here, you can remove it.
        }
      });

      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);

      // SMS sent. Prompt the user to type the code from the message.
      setVerificationId(confirmationResult.verificationId);
      alert('Verification code sent to your phone!');
    } catch (error) {
      console.error('Error sending verification code:', error);
    }
  };

  const handleVerifyCode = async () => {
    try {
      const credential = auth.PhoneAuthProvider.credential(verificationId, code);
      await auth.signInWithCredential(credential);
      alert('Phone number verified successfully!');
    } catch (error) {
      console.error('Error verifying code:', error);
    }
  };

  return (
    <div>
      <h2>Phone Authentication</h2>
      <label>
        Phone Number:
        <input type="text" value={phoneNumber} onChange={handlePhoneNumberChange} />
      </label>
      <br />
      <button id="sign-in-button" onClick={handleSendCode}>Send Verification Code</button>
      <br />
      <label>
        Verification Code:
        <input type="text" value={code} onChange={handleCodeChange} />
      </label>
      <br />
      <button onClick={handleVerifyCode}>Verify Code</button>
    </div>
  );
};

export default PhoneAuth;
