import DOMPurify from "dompurify";

export const containsUnsafeCharacters = (input) => {
  console.log(input);
  const sanitizedInput = DOMPurify.sanitize(input);
  console.log(sanitizedInput);
  return sanitizedInput !== input;
};

export const validate = (value) => !!value;
