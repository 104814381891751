import React, { useContext } from "react";
import { logosInfo } from "./logosInfo";
import Logo from "./Logo";
import LanguageContext from "../../../storage/LanguageContext";

function Logos({ socials }) {
  const logos = JSON.parse(socials).map((item) => (
    <a href={item.link} key={item.label}>
      <Logo label={item.label} xlink={item.xlink} />
    </a>
  ));
  return <>{logos}</>;
}

export default Logos;
