import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import NavItems from "./NavItems";
import Socials from "../lists/Socials";
import SecondaryBt from "../UI/buttons/SecondaryBt";
import Dropdown from "../UI/dropdown/Dropdown";
import LanguageContext from "../../storage/LanguageContext";
import { sanitizeAndParse } from "../../utils/function.helpers";
import { FcGlobe } from "react-icons/fc";
import { scrollTo } from "../../utils/function.helpers";

function Navigation() {
  const [navLeftActive, setNavLeftActivite] = useState(false);

  const { switchLanguage, copy, language } = useContext(LanguageContext);

  const toggleHamburgerHandler = () => {
    setNavLeftActivite((prev) => !prev);
  };

  const navLeftClassName = `navigation__left ${navLeftActive && "active"}`;
  const menuClassName = `menu ${navLeftActive && "active"}`;
  return (
    <header className="navigation">
      <div className="container">
        <nav>
          <div className="hamburger" onClick={toggleHamburgerHandler}>
            <div className={menuClassName}></div>
          </div>
          <Link to="/" className="logo">
            <img src={logo} alt="" />
          </Link>
          <div className={navLeftClassName}>
            <NavItems onClick={toggleHamburgerHandler} />
            <Socials addClass={"navigation__left-socials"} />
            <div className="navigation__left-footer">
              <SecondaryBt
                isLarge={true}
                link="/contact"
                text={sanitizeAndParse(copy.contact_button)}
                onClick={toggleHamburgerHandler}
              />
            </div>
          </div>
          <div className="navigation__right">
            <SecondaryBt
              text={sanitizeAndParse(copy.contact_button)}
              link="/contact"
              extra="hide-on-small-screens"
              onClick={() => {
                scrollTo("contact-form");
              }}
            />
            <Dropdown
              options={["en", "ka"]}
              onChange={(val) => {
                switchLanguage(val);
              }}
              icon={<FcGlobe />}
              value={language}
            />
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Navigation;
