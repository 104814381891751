import React, {useEffect} from "react";

export const AppleCallback = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');

        console.log('Sending authCode:', authCode);
        if (authCode) {
            // Here, you have the authCode. You can now communicate it to your main application window.
            window.opener.postMessage({ authCode }, window.origin);
            window.close(); // Close the popup

            console.log('authCode sent');
        }
    }, []);


    return <div>Processing...</div>;
};
