import red from "../../assets/img/gem-red.png";
import blue from "../../assets/img/gem-blue.png";
import green from "../../assets/img/gem-green.png";
import purple from "../../assets/img/gem-purple.png";
import yellow from "../../assets/img/gem-yellow.png";

export const images = [
  {
    name: "red",
    img: red,
  },
  {
    name: "blue",
    img: blue,
  },
  {
    name: "green",
    img: green,
  },
  {
    name: "purple",
    img: purple,
  },
  {
    name: "yellow",
    img: yellow,
  },
];
